import React from 'react'
import styled from "styled-components";
// import { Button, Form} from 'react-bootstrap';

import {API} from 'aws-amplify';

import Amplify from 'aws-amplify';
import aws_exports from '../aws-exports';

Amplify.configure(aws_exports);


async function addContact() {
    const data = {
      body: {
        name: formState.name,
        email: formState.email,
        message: formState.message
      }
    };
  
    console.log(data);
    const apiData = await API.post('formapi', '/contact', data);
    console.log({ apiData });
    alert('Mail sent');
  }
  
  const formState = { name: '', email: '', message: '' };
  
  function updateFormState(key, value) {
    formState[key] = value;
  }

function Contact() {

  return (
    // <Container>
    //   <h3>Get in touch</h3>
    //   <br/>
    //     <form>
    //       <Form.Group>
    //         <Form.Label>Name</Form.Label>
    //         <input placeholder="Name" onChange={e => updateFormState('name', e.target.value)} />
    //       </Form.Group>
    //       <Form.Group>
    //         <Form.Label>Email</Form.Label>
    //         <input placeholder="Email" onChange={e => updateFormState('email', e.target.value)} />
    //       </Form.Group>
    //       <Form.Group>
    //         <Form.Label>Message</Form.Label>
    //         <textarea placeholder="Message" onChange={e => updateFormState('message', e.target.value)} />
    //       </Form.Group>
    //       <Button onClick={addContact}>Send a message</Button>
    //     </form>
    // </Container>

    <Container>
        <form>
        <h3>Get in touch</h3>
            <input placeholder="Name" onChange={e => updateFormState('name', e.target.value)} />
            <input placeholder="Email" onChange={e => updateFormState('email', e.target.value)} />
            <textarea placeholder="Message" onChange={e => updateFormState('message', e.target.value)} />
          <button onClick={addContact}>Send a message</button>
        </form>
    </Container>

    // <Container>
    //     <form action="https://formsubmit.co/your@email.com" method="POST">
    //         <h3>GET IN TOUCH</h3>
    //         <input type="text" id="name" name='name' placeholder='Your Name' required />
    //         <input type="email" id="email" name='email' placeholder='Email id' required />
    //         <input type="text" id="phone" name='phone' placeholder='Phone no.' required />
    //         <input type="hidden" name="_next" value="https://yourdomain.co/thanks.html"></input>
    //         <textarea id="message" name='message' rows='4' placeholder='How can we help you?'></textarea>
    //         <button type='submit' onClick={addContact}>Send</button>
    //     </form>
    // </Container>
  )
}

export default Contact

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background: #001660;
    display: flex;
    align-items: center;
    justify-content: center;
    form {
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: 2vw 4vw;
        width: 90%;
        max-width: 600px;
        border-radius: 10px;
        h3{
            color: #555;
            font-weight: 800;
            margin-bottom: 20px;
        }
        input, textarea{
            border:0;
            margin: 10px 0;
            padding: 20px;
            outline: none;
            background: #f5f5f5;
            font-size: 16px;
        }
        button {
            padding: 15px;
            background: lightblue;
            color: black;
            font-size: 18px;
            border: 0;
            outline: none;
            cursor: pointer;
            width: 150px;
            margin: 20px auto 0;
            border-radius: 30px;
        }
    }
`
export const faqData = [
  {
    question: "What areas do you operate in?",
    answer: "Predominantly in North London. Monday and Tuesday collection areas: N9, N20, N21 - Thursday collection areas: N6, N7, N8, N10, N11, N13, N14, N18, N19, N22, NW3, NW5",
  },
  {
    question: "What are your pick up and delivery times?",
    answer:
      "Our drivers depart between 5-6pm and usually finish around 8:30pm. They follow a set itinerary to every customer on the list that day which means there will be various arrival times dependant on your position on the itinerary.",
  },
  {
    question: "Can I drop my items in?",
    answer: "Yes. Our shop is located at 3 Mottingham Road, Edmonton, N9 8DX",
  },
  {
    question: "What shall I do with my ironing before collection?",
    answer: "Place your ironing in a laundry or plastic bag that will enable our drivers to weight them at the door. Any side dry cleaning, washing or alteration pieces please place in a seperate bag.",
  },
  {
    question: "When will my clothes be delivered?",
    answer: "Your clothes will be delivered the next working day unless told otherwise. If your items contain extra items such as dry cleaning or alterations this could delay delivery.",
  },
  {
    question: "How does the process work?",
    answer: "Give us a call or text to discuss collection days for your area. A driver will then collect your ironing on the discussed day whilst weighing and pricing your ironing at the door. Your ironing is then delivered to our shop where our maids will prepare and package your clothes for delivery.",
  },
  {
    question: "What do I do with my hangers from a previous order?",
    answer: "We ask all our customers to return hangers back to us to be recycled. We believe in reducing waste and remaining environmentally friendly.",
  },
  {
    question: "How do I contact you?",
    answer: "Call us on 0208 482 6367 to discuss collections and any enquiries you have. You can also text us on 07 to arrange collections.",
  },
  {
    question: "How do I pay?",
    answer: "Our drivers do not carry card readers and accept cash upon delivery of your clothes.",
  },
  {
    question: "What are your shop opening times?",
    answer: "Tuesday, Wednesday and Friday: 9am - 5pm, Saturday: 10am - 2pm",
  },
  {
    question: "Can I cancel my order?",
    answer: "Please call us ASAP to inform us of your cancellation to avoid wasted journeys to your property.",
  },
  {
    question: "I am going to be late, can I reschedule?",
    answer: "Please call us ASAP to inform us of your situation and to reschedule. Please note rescheduled collections have to coincide with our specified collection days for your area.",
  },
];

import React, { useState } from "react";
import { css } from "styled-components/macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { menuData } from "../data/menuData";
import { Button } from "./Button";
import { FaBars } from "react-icons/fa";
import Background from "../images/argyle.png";
import LaundryLogo from "../images/laundry-logo.png";
import "../App.css";

const Navbar = ({ toggle }) => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const [textColorChange, setTextColorchange] = useState(false);
  const changeNavbarTextColor = () => {
    if (window.scrollY >= 80) {
      setTextColorchange(true);
    } else {
      setTextColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarTextColor);

  return (
    <Nav className={colorChange ? "navbar colorChange" : "navbar"}>
      <div className="flex">
        <img className="navbarImageLogo" src={LaundryLogo} alt="" />
        <Logo
          to="/"
          className={
            textColorChange ? "navbarText textColorChange" : "navbarText"
          }
        >
          Laundry Maid Easy
        </Logo>
      </div>

      <MenuBars onClick={toggle} />
      <NavMenu>
        {menuData.map((item, index) => (
          <NavMenuLinks
            className={
              textColorChange ? "navbarText textColorChange" : "navbarText"
            }
            to={item.link}
            key={index}
          >
            {item.title}
          </NavMenuLinks>
        ))}
      </NavMenu>
      <NavBtn>
        <Button to="/contact" primary="true">
          Contact Us
        </Button>
      </NavBtn>
    </Nav>
  );
};

const Nav = styled.nav`
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0rem 2rem;
  z-index: 100;
  position: fixed;
  width: 100%;
  background: transparent;
  //background-color: #030514;
  //background-image: url(${Background});

  @media screen and (max-width: 768px) {
    transition: 0.8s all ease;
    justify-content: center;
  }
`;

const NavLink = css`
  color: #d9ecfa;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  font-size: 30px;
  text-shadow: 2px 2px 4px black;
  text-align: center;
  font-size: clamp(0.7rem, 4vw, 1.5rem);
  :hover {
    color: #88e0ef;
    transition: 0s;
  }
`;

const Logo = styled(Link)`
  ${NavLink}
  font-style: italic;
  //font-family: "Lobster", cursive;
  //font-size: 30px;
`;

const MenuBars = styled(FaBars)`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    background-size: contain;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 25%);
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -48px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavMenuLinks = styled(Link)`
  ${NavLink}
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-family: "Lobster", cursive;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export default Navbar;

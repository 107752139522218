import React from "react";
import styled from "styled-components";
//import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import Background from "../images/argyle.png";
import LaundryLogo from "../images/laundry-logo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <FooterContainer>
      <FooterLinks>
        <FooterLinkWrapper>
          <FooterLinkItems>
            <FooterLinkItemsTitle>About Us</FooterLinkItemsTitle>
            <FooterLinkItemsAnchor href="/about">About</FooterLinkItemsAnchor>
            <FooterLinkItemsAnchor href="/#howItWorks">
              How It Works
            </FooterLinkItemsAnchor>
            <FooterLinkItemsAnchor href="/faq">FAQ</FooterLinkItemsAnchor>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkItemsTitle>Contact Us</FooterLinkItemsTitle>
            <FooterLinkItemsAnchor href="/contact">
              Contact
            </FooterLinkItemsAnchor>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkItemsTitle>Social Media</FooterLinkItemsTitle>
            <FooterLinkItemsAnchor
              href="https://www.facebook.com/LaundryMaidEasyLTD"
              target="_blank"
            >
              Facebook
            </FooterLinkItemsAnchor>
            <FooterLinkItemsAnchor
              href="https://www.instagram.com"
              target="_blank"
            >
              Instagram
            </FooterLinkItemsAnchor>
            <FooterLinkItemsAnchor
              href="https://www.twitter.com"
              target="_blank"
            >
              Twitter
            </FooterLinkItemsAnchor>
          </FooterLinkItems>
        </FooterLinkWrapper>
      </FooterLinks>
      <SocialMedia>
        <SocialMediaWrapper>
          <div className="centerThing">
            <img className="imageLogo" src={LaundryLogo} alt="" />
            <FooterLogo to="/">Laundry Maid Easy</FooterLogo>
          </div>
          {/*<WebsiteRights>Laundry Maid Easy © 2022</WebsiteRights>*/}
          <SocialIcons>
            <SocialIconLink
              href="https://www.facebook.com/LaundryMaidEasyLTD"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink
              href="www.instagram.com"
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.twitter.com"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrapper>
      </SocialMedia>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  ${"" /* background-color: #072227; */}
  ${"" /* background-color: #88e0ef; */}
  //background-image: url(${Background});
  //background-color: #030514;
  //background-color: #c3cdd4;
  background-color: white;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const FooterLinks = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterLinkWrapper = styled.div`
  display: flex;
  //width: 60vw;
  justify-content: space-between;
  width: 70%;
`;

const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  ${"" /* width: 160px; */}
  box-sizing: border-box;
  color: black;
  text-decoration: none;

  @media screen and (max-width: 768px) {
    margin: 12px;
    text-align: center;
    align-items: center;
  }
`;

// const FooterLinkItemsAnchor = styled(Link)`
//   color: black;
//   text-decoration: none;
//   margin-bottom: 0.5rem;
//   cursor: pointer;
//   @media screen and (max-width: 768px) {
//     text-align: center;
//   }
// `;

const FooterLinkItemsAnchor = styled.a`
  color: black;
  text-decoration: none;
  margin-bottom: 0.5rem;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const FooterLinkItemsTitle = styled.h2`
  margin-bottom: 16px;
  color: black;
`;

const SocialMedia = styled.div`
  max-width: 1000px;
  width: 100%;
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
`;

const FooterLogo = styled.div`
  color: black;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

// const WebsiteRights = styled.div`
//   color: black;
//   margin-bottom: 16px;
// `;

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
`;

const SocialIconLink = styled.a`
  color: black;
  font-size: 24px;
  cursor: pointer;
`;

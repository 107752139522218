import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
//import styled, { css } from "styled-components/macro";
import { Button } from "./Button";
import { IoMdArrowRoundForward } from "react-icons/io";
//import { IoArrowForward, IoArrowBack } from "react-icons/io5";
//import {Link} from 'react-router-dom'
import LaundryLogo from "../images/laundry-logo.png";

const Welcome = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;
  const timeout = useRef(null);

  useEffect(() => {
    const nextSlide = () => {
      setCurrent((current) => (current === length - 1 ? 0 : current + 1));
    };

    timeout.current = setTimeout(nextSlide, 7000);

    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [current, length]);

  // const nextSlide = () => {
  //   if (timeout.current) {
  //     clearTimeout(timeout.current);
  //   }

  //   setCurrent(current === length - 1 ? 0 : current + 1);
  // };

  // const prevSlide = () => {
  //   if (timeout.current) {
  //     clearTimeout(timeout.current);
  //   }

  //   setCurrent(current === 0 ? length - 1 : current - 1);
  // };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <WelcomeSection>
      <WelcomeWrapper>
        {slides.map((slide, index) => {
          return (
            <WelcomeSlide key={index}>
              {index === current && (
                <WelcomeSlider>
                  <WelcomeImage src={slide.image} alt={slide.alt} />
                  <WelcomeContent>
                    <LaundryLogoImg src={LaundryLogo} alt="" />
                    <h1>{slide.title}</h1>
                    <p>{slide.subtitle}</p>
                    <Button to={slide.path} primary="true">
                      {slide.label}
                      <Arrow />
                    </Button>
                  </WelcomeContent>
                </WelcomeSlider>
              )}
            </WelcomeSlide>
          );
        })}

        {/*<SliderButtons>
          <PrevArrow onClick={prevSlide} />
          <NextArrow onClick={nextSlide} />
      </SliderButtons>*/}

        <FadeBottom />
      </WelcomeWrapper>
    </WelcomeSection>
  );
};

const WelcomeSection = styled.section`
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const WelcomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const WelcomeSlide = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
`;
const WelcomeSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0.4;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }
`;
const WelcomeImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;
const WelcomeContent = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  ${"" /* width:calc(100% - 100px); */}
  width: 100%;
  color: #fff;
  align-items: center;
  bottom: calc(100vh - 80%);

  h1 {
    font-size: clamp(1rem, 8vw, 2.5rem);
    font-weight: 500;
    text-transform: uppercase;
    ${"" /* text-shadow:0px 0px 20px rgba(0,0,0,0.4); */}
    text-shadow: 2px 2px 4px #000000;
    text-align: center;
    margin-bottom: 0.8rem;
    padding: 10px;
    @media screen and (max-width: 768px) {
      font-size: 40px;
    }
  }
  p {
    margin-bottom: 1.2rem;
    text-shadow: 2px 2px 4px #000000;
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 30px;
      padding: 0 10px;
    }
  }
`;
const LaundryLogoImg = styled.img`
  z-index: 999;
  max-height: 50vh;
  padding-top: 20px;
  filter: drop-shadow(2px 2px 5px grey);
  //filter: brightness(0) invert(1);
`;
const Arrow = styled(IoMdArrowRoundForward)`
  margin-left: 0.5rem;
`;

// const SliderButtons = styled.div`
//   position: absolute;
//   bottom: 50px;
//   right: 50px;
//   display: flex;
//   z-index: 10;
// `;

// const arrowButtons = css`
//   width: 50px;
//   height: 50px;
//   color: #fff;
//   cursor: pointer;
//   background: #000d1a;
//   border-radius: 50px;
//   padding: 10px;
//   margin-right: 1rem;
//   user-select: none;
//   transition: 0.3s;

//   &:hover {
//     background: #cd853f;
//     transform: scale(1.05);
//   }
// `;

const FadeBottom = styled.div`
  height: 7.4rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37, 37, 37, 0.61),
    #111
  );
  z-index: 50;
`;

// const PrevArrow = styled(IoArrowBack)`
//   ${arrowButtons}
// `;

// const NextArrow = styled(IoArrowForward)`
//   ${arrowButtons}
// `;

export default Welcome;

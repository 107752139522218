import React from "react";
//import Ironing1 from "../images/ironing.jpg";
import styled from "styled-components";

function About() {
  return (
    <>
      {/* <AboutWelcomeImage src={Ironing1} /> */}
      <AboutUsSection>
        {/* <h2>About Us</h2>
        <div className="underline"></div> */}
        <AboutUsOpeningParagraph>
          YOUR FRESHLY PACKAGED IRONING DELIVERED DIRECTLY TO YOUR DOOR
        </AboutUsOpeningParagraph>
        <p>
          Please feel free to contact us to learn more about our services and to
          discuss collection dates.
        </p>
        <p>
          At Laundry Maid Easy we understand the hassle of ironing. It's a
          strenuous task that can take you hours - especially in family or
          working households and it's not something you want on your mind after
          a long day. Let us cut that hassle from your life to allow you to
          focus on the more important aspects of your day-to-day routines. We
          collect your bag of ironing from your door which will then be
          specially prepared by our maids and delivered back to you the next
          working day. No hassle. No Fuss.
        </p>
        <p>
          Operating predominantly in the North London area since 1974 we have
          years of experience in ironing, dry cleaning, washing and alterations.
        </p>
        <p>
          Feel free to pop in-store if you rather speak in person about any
          enquires. Our in-store opening times are:
        </p>
        <p>
          TUESDAY: 9am - 5pm
          <br />
          WEDNESDAY: 9am - 5pm
          <br />
          FRIDAY: 9am - 5pm
          <br />
          SATURDAY: 10am - 2pm
        </p>
      </AboutUsSection>
    </>
  );
}

export default About;

// const AboutWelcomeImage = styled.img`
//   position: static;
//   width: 100%;
//   height: 400px;
//   object-fit: cover;
// `;
const AboutUsSection = styled.div`
  padding: 30px;
  background-color: black;
  color: white;
  h2 {
    color: blue;
    font-size: 35px;
    font-weight: 750;
  }
  p {
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const AboutUsOpeningParagraph = styled.p`
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 30px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

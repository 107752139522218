import React from "react";
import ClothesRack from "../images/clothes-rack.jpg";
import PricingBackground from "../images/black-linen.png";
import PricingBackgroundArgyle from "../images/argyle.png";
import styled from "styled-components";

function Pricing() {
  return (
    <>
      <PricingContainer>
        <WelcomeImageSection>
          <PricingWelcomeImage src={ClothesRack} />
          <TextOnImage>Pricing</TextOnImage>
        </WelcomeImageSection>
        <PricingDescriptionContainer>
          <div>
            <IroningTitle>Ironing</IroningTitle>
            <IroningDescription>
              <p>
                £6 per kilogram. We price our ironing based off the total weight
                of your bag of ironing.
              </p>
            </IroningDescription>
          </div>

          <div>
            <WashingTitle>Washing</WashingTitle>
            <WashingDescription>
              <p>Our washing services are £3.50 per kilogram.</p>
              <p>Washing AND ironing is £7.50 per kilogram.</p>
              <p>Washing powder and drying included.</p>
              <p>
                We also offer services on incontinent laundry. Give us a call to
                discuss pricing and plans.
              </p>
            </WashingDescription>
          </div>

          <div className="borderBottom">
            <DryCleaningTitle>Dry Cleaning</DryCleaningTitle>
            <DryCleaningDescription>
              <table>
                <tr>
                  <th>Item:</th>
                  <th>Price:</th>
                </tr>
                <tr>
                  <td>Suits </td>
                  <td>From £7.00</td>
                </tr>
                <tr>
                  <td>School Blazer</td>
                  <td>£4.00</td>
                </tr>
                <tr>
                  <td>Shirts</td>
                  <td>£2.00</td>
                </tr>
                <tr>
                  <td>Skirts</td>
                  <td>From £3.00</td>
                </tr>
                <tr>
                  <td>Dresses</td>
                  <td>From £5.00</td>
                </tr>
                <tr>
                  <td>Ties</td>
                  <td>£2.00</td>
                </tr>
                <tr>
                  <td>Scarfs</td>
                  <td>£2.50</td>
                </tr>
                <tr>
                  <td>Jumpers</td>
                  <td>From £3.00</td>
                </tr>
                <tr>
                  <td>Coats</td>
                  <td>From £6.50</td>
                </tr>
              </table>
            </DryCleaningDescription>
          </div>

          <div>
            <AlterationsTitle>Alterations</AlterationsTitle>
            <AlterationsDescription>
              <p>
                From hemming jeans to fixing holes, our alterations specialist
                has you covered. Feel free to give us a call to discuss your
                particular situation.
              </p>
              <p>
                Please note with zip replacements you will have to provide your
                own zip.
              </p>
            </AlterationsDescription>
          </div>
        </PricingDescriptionContainer>
      </PricingContainer>
    </>
  );
}

export default Pricing;

const WelcomeImageSection = styled.div`
  position: relative;
  height: 250px;
  overflow: hidden;
  margin-bottom: 50px;
`;

const PricingWelcomeImage = styled.img`
  position: relative;
  text-align: center;
  width: 100%;
  height: 35vh;
  object-fit: cover;
`;

const TextOnImage = styled.h1`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  //margin-top: -7%;
  //font-family: "Lobster", cursive;
  font-size: 50px;
  color: #88e0ef;
  text-shadow: 2px 2px 4px #000000;
`;

const PricingContainer = styled.div`
  //background-color: #aefeff;
  //background-color: #dff6ff;
  background-color: #31a7eb;
  padding-bottom: 20px;
`;

const PricingDescriptionContainer = styled.div`
  margin: 30px;

  background-color: white;
  border-radius: 50px;
  // div:nth-child(even) {
  //   //background-image: url(${PricingBackground});
  //   background-color: #90E0EF;
  // }
  // div:nth-child(odd) {
  //   //background-image: url(${PricingBackgroundArgyle});
  //   //background-color: #88e0ef;
  // }
  h2,
  h3 {
    padding: 20px;
    text-align: center;
  }
  p {
    padding: 1px 10px;
    text-align: center;
  }
`;

// const ListOfPricesTitle = styled.h2`
//   font: bold;
//   color: #88e0ef;
//   display: flex;
//   align-items: center;
//   font-size: 30px;
//   text-shadow: 2px 2px 4px #000000;
//   margin: 0;
// `;

const IroningTitle = styled.h3`
  font: bold;
  color: #000;
  font-size: 25px;
  padding-top: 0;
  margin-top: -2%;
`;

const IroningDescription = styled.p`
  margin-top: 10px;
  margin-bottom: 0;
  border-bottom: 2px solid #000;
  margin-left: 20px;
  margin-right: 20px;
`;
const WashingTitle = styled.h3`
  font: bold;
  color: #000;
  font-size: 25px;
`;
const WashingDescription = styled.p`
  margin-bottom: 0;
  border-bottom: 2px solid #000;
  margin-left: 20px;
  margin-right: 20px;
`;
const DryCleaningTitle = styled.h3`
  font: bold;
  color: #000;
  font-size: 25px;
  padding-bottom: 15px;
`;
const DryCleaningDescription = styled.table`
  padding-bottom: 50px;

  //background-color: #88e0ef;
  margin-left: auto;
  margin-right: auto;
  //border-radius: 10px;
  table {
    width: 60vw;
    padding-right: 20px;
    border-collapse: collapse;
    margin-bottom: 30px;
  }
  tr:nth-child(0) {
    background-color: #88e0ef;
  }
  tr:nth-child(even) {
    background-color: #31a7eb;
    //background-image: url(${PricingBackground});
  }
  tr:nth-child(odd) {
    background-color: #030514;
    background-image: url(${PricingBackground});
    color: #88e0ef;
  }
  td:nth-child(2) {
    text-align: end;
  }
  th {
    text-align: center;
  }
  th,
  td {
    padding: 10px;
  }
`;
const AlterationsTitle = styled.h3`
  font: bold;
  color: #000;
  font-size: 25px;
`;
const AlterationsDescription = styled.p`
  //margin-bottom: 0;
  //border-bottom: 2px solid #000;
  margin-left: 20px;
  margin-right: 20px;
`;

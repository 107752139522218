import React from "react";
import styled from "styled-components";
//import AreaLondon from "../images/LONDON_post_town_map.svg";
//import London1941 from "../images/rocques_map_london_1741.jpg";
//import MagnifyingGlassMap from "../images/magnifying-glass-over-map-showing-london-4908161.jpg";

function Areas() {
  return (
    <AreasSection>
      <WelcomeImageSection>
        {/* <AreasWelcomeImage src={MagnifyingGlassMap} /> */}
        <AreasWelcomeImage />
        <TextOnImage>AREAS WE COVER</TextOnImage>
      </WelcomeImageSection>

      <AreasOpeningParagraph>
        The postal code areas we cover. Our collection days vary depending on
        certain areas.
      </AreasOpeningParagraph>
      <div class="container">
        <div class="row">
          <div class="col">
            <p>Monday and Tuesday Collection Areas</p>
            <ul>
              <li>N9</li>
              <li>N20</li>
              <li>N21</li>
            </ul>
          </div>
          <div class="col">
            <p>Thursday Collection Areas</p>
            <div className="container">
              <div className="row">
                <div className="col">
                  <ul>
                    <li>N6</li>
                    <li>N7</li>
                    <li>N8</li>
                    <li>N10</li>
                    <li>N11</li>
                    <li>N13</li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li>N14</li>
                    <li>N18</li>
                    <li>N19</li>
                    <li>N22</li>
                    <li>NW3</li>
                    <li>NW5</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p>
        We aim to extend our operations and plan to add more collection days and
        areas in the future.
      </p>
      <p>Follow us on social media for future updates.</p>
    </AreasSection>
  );
}

export default Areas;

const AreasSection = styled.div`
  //background-color: #c3cdd4;
  color: white;
  background-color: black;
  padding-bottom: 30px;
  p {
    text-align: center;
    font-size: 25px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 0;
  }
  ul {
    text-align: center;
    list-style-position: inside;
  }
  li {
    list-style-type: none;
    font-size: 25px;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
`;

const WelcomeImageSection = styled.div`
  position: static;
  padding-bottom: 5px;
  height: 250px;
  overflow: hidden;
`;

const AreasWelcomeImage = styled.div`
  height: 250px;
  width: 100vw;
  object-fit: cover;
  ${"" /* position: absolute; */}
  // filter: grayscale(20%) blur(0.6px);
  background-color: white;
`;

const TextOnImage = styled.h1`
  position: relative;
  text-align: center;
  margin-top: -5%;
  //font-family: "Lobster", cursive;
  font-size: 50px;
  color: #88e0ef;
  text-shadow: 2px 2px 4px #000000;
  @media screen and (max-width: 768px) {
    margin-top: -30%;
  }
`;

const AreasOpeningParagraph = styled.p`
  padding-top: 30px;
  overflow: hidden;
  font-size: 25px;
`;

import React from "react";
import { Heading } from "../globalStyles";
//import { IconContext } from 'react-icons/lib';
import styled from "styled-components";
import "../App.css";
import Background from "../images/black-linen.png";

function HowItWorks({ cards }) {
  return (
    //<IconContext.Provider value={{ color: '#a9b3c1', size: '1rem' }}>
    <HowItWorksSection id="howItWorks">
      <HowItWorksWrapper>
        <Heading>How It Works</Heading>
        <CardContainer>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardInfo>
                <StepNumber>{card.stepNumber}</StepNumber>
                <StepTitle>{card.stepTitle}</StepTitle>
                {/* <CardText>{card.stepDescription}</CardText> */}
                <CardFeatures>
                  {card.stepDescription.map((feature, index) => (
                    <CardFeature key={index}>{feature}</CardFeature>
                  ))}
                </CardFeatures>
                {/* <Button>Get Started</Button> */}
              </CardInfo>
            </Card>
          ))}
        </CardContainer>
      </HowItWorksWrapper>
    </HowItWorksSection>
    //</IconContext.Provider>
  );
}

const HowItWorksSection = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #88e0ef;
  height: 100%;
  //background-color: #d9ecfa;
  //background-color: #c3cdd4;
  background-color: white;
  //background-image: url(${Background});
`;

const HowItWorksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;

  > div {
    margin: 0.7rem;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    > div {
      width: 90%;
    }
  }
`;

const Card = styled.div`
  &:hover {
    transform: scale(1.02);
    transition: all 0.3s ease-out;
  }
`;

const CardInfo = styled.div`
  //background: #35858b;
  background-color: black;
  //background-image: url(${Background});
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 240px;
  text-decoration: none;
  border-radius: 4px;
  height: 100%;
  text-align: center;

  display: flex;
  flex-direction: column;
  padding: 10px;
  color: #072227;
  margin: auto;
  > button {
    margin-top: auto;

    &:hover {
      color: black;
      background: white;
      transition: background 0.3s ease;
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;

    &:hover {
      transform: none;
    }
  }
`;

const StepNumber = styled.h3`
  font-size: 2rem;
  color: white;
`;

const StepTitle = styled.h4`
  font-size: 1.2rem;
  color: white;
`;

const CardFeatures = styled.ul`
  margin: 16px 0 16px;
  list-style: none;
  padding-left: 0;
`;

const CardFeature = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: rgba(265, 265, 265, 0.7);
`;

export default HowItWorks;

import React from "react";
import Welcome from "../components/Welcome";
import { sliderData } from "../data/sliderData";
// import Services from './Services';
import HowItWorks from "../components/HowItWorks";
// import Pricing from '../../Pricing';
import { howItWorksData } from "../data/howItWorksData";
import About from "./About";

function Home() {
  return (
    <>
      <Welcome slides={sliderData} />
      <HowItWorks cards={howItWorksData} />
      <About />

      {/* <HeroSection {...homeWelcomeScreen} /> */}

      {/* <Pricing /> */}
    </>
  );
}

export default Home;

export const howItWorksData = [
  {
    stepNumber: "1",
    stepTitle: "Arrange a Collection",
    stepDescription: [
      "Call or text us to arrange a collection day",
      "Find out what days we cover your area",
    ],
  },
  {
    stepNumber: "2",
    stepTitle: "Collection",
    stepDescription: [
      "One of our drivers will be sent out to you in the evening of the arranged day",
      "We weigh your ironing using our handheld scales and price your clothes at the door",
    ],
  },
  {
    stepNumber: "3",
    stepTitle: "Preparing Your Clothes",
    stepDescription: [
      "Your clothes will be delivered back to the shop where our maids will prepare your items for delivery",
    ],
  },

  {
    stepNumber: "4",
    stepTitle: "Delivery",
    stepDescription: ["Next day delivery of your freshly ironed clothes", ""],
  },
];

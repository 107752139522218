import ImageOne from '../images/ironing-1.jpg'
import ImageTwo from '../images/man-shirt-torso.jpg'
// import ImageThree from '../images/house-3.jpg'
// import ImageFour from '../images/house-4.jpg'

export const sliderData=[
    {
        title: 'Door to Door Ironing Service',
        subtitle: 'Ironing. Dry Cleaning. Washing. Alterations.',
        path: '/areas',
        label: 'Areas We Cover',
        image: ImageOne,
        alt: 'House'
    },
    {
        title: 'Door to Door Ironing Service',
        subtitle: 'Ironing. Dry Cleaning. Washing. Alterations.',
        price: '$2,860,000',
        path: '/areas',
        label: 'Areas We Cover',
        image: ImageTwo,
        alt: 'House'
    }
]

// export const SliderDataTwo=[
//     {
//         title: 'House in Las Vegas, Nevada',
//         price: '$8,980,000',
//         path: '/homes',
//         label: 'View Home',
//         image: ImageFive,
//         alt: 'House'
//     },
//     {
//         title: 'House in Houston, Texas',
//         price: '$3,460,000',
//         path: '/homes',
//         label: 'View Home',
//         image: ImageSix,
//         alt: 'House'
//     },
//     {
//         title: 'House in Alajuela, Costa Rica',
//         price: '$5,420,000',
//         path: '/homes',
//         label: 'View Home',
//         image: ImageSeven,
//         alt: 'House'
//     },
// ];
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Amplify from 'aws-amplify'
import config from './aws-exports'
import '@aws-amplify/ui-react/styles.css'
Amplify.configure(config)



ReactDOM.render(<App />, document.getElementById("root"));

import React, { useState } from "react";
import { faqData } from "../data/faqData";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { FiPlus, FiMinus } from "react-icons/fi";
import Background from "../images/black-linen.png";

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  //background-image: url(${Background});
  background-color: black;
`;
const Container = styled.div`
  position: static;
  margin-top: 50px;
  padding: 10px;
  //box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
  
  width: 100%;
`;
const Wrap = styled.div`
  background: black;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;

  h1 {
    padding: 1rem;
    font-size: 14px;
  }
  span {
    margin-right: 1.5rem;
    color: red;

  }
  :hover{
    background-color:#001660;
  }
`;
const Dropdown = styled.div`
  background: #1c1c1c;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  color: white;


  p {
    font-size: 2rem;
    margin-top :10px;
    margin-bottom :10px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const FAQ = () => {
  const [clicked, setClicked] = useState(false);
  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close
      return setClicked(null);
    }
    setClicked(index);
  };
  return (
    <IconContext.Provider value={{ color: "blue", size: "25px" }}>
      <AccordionSection>
        <Container>
          {faqData.map((item, index) => {
            return (
              <>
                <Wrap onClick={() => toggle(index)} key={index}>
                  <h1>{item.question}</h1>
                  <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                </Wrap>
                {clicked === index ? (
                  <Dropdown>
                    <p>{item.answer}</p>
                  </Dropdown>
                ) : null}
              </>
            );
          })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
  );
};

export default FAQ;

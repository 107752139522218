import React, { useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Dropdown from "./components/Dropdown";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { sliderData } from "./data/sliderData";
import Areas from "./pages/Areas";
import About from "./pages/About";
import Footer from "./components/Footer";
import FAQ from "./components/FAQ";
import PricingPage from "./pages/PricingPage";
import Contact from "./components/Contact";
// import Products from './components/pages/Products/Products';
// import SignUp from './components/pages/SignUp/SignUp';
// import Footer from './components/pages/Footer/Footer';

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Router>
      <Navbar toggle={toggle} />
      <div style={{ overflowY: "scroll", height: "0px" }}></div>
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <Routes>
        <Route path="/" element={<Home />} slides={sliderData} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/areas" element={<Areas />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path='/products' element={<Products/>} />
        <Route path='/sign-up' element={<SignUp/>} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
